<template>
  <div id="projects">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Completed Project List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search Customer or SKU" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Date</div>
            <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" type="daterange" align="right" unlink-panels range-separator="To" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Country</div>
            <el-select
              v-model="filter.CountryId"
              filterable
              placeholder="Select"
              @clear="
                filter.CountryId = null;
                filter.CityId = null;
              "
              clearable
            >
              <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">City</div>
            <el-select v-model="filter.CityId" filterable placeholder="Select" @clear="filter.CityId = null" clearable :disabled="filter.CountryId == null">
              <template v-if="getAllCountries.some((x) => x.country_id == filter.CountryId)">
                <el-option v-for="item in getAllCountries.find((x) => x.country_id == filter.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
              </template>
            </el-select>
          </el-col>
        </el-row>

        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Product Manager</div>
            <el-select v-model="filter.ProductManagerId" placeholder="Select" clearable>
              <el-option v-for="(item, index) in getUserList.filter((x) => x.Role == 10 && x.IsDeactivated != true)" :key="index" :value="item.ID" :label="item.FirstName + ' ' + item.LastName"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Stage</div>
            <el-select v-model="filter.Stage" placeholder="Select" clearable @clear="filter.Stage = null">
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'JobStage').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Status</div>
            <el-select v-model="filter.Status" placeholder="Select" clearable @clear="filter.Status = null">
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'JobStatus').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Supplier</div>
            <el-select v-model="filter.SupplierId" placeholder="Select" clearable filterable>
              <el-option v-for="(item, index) in suppliersList.filter((x) => x.IsDeactivated != true && x.SupplierType==0)" :key="index" :value="item.ID" :label="item.Title"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <!-- :row-key="row => row.ID" :expand-row-keys="expandedKeys" -->
      <el-table v-if="completedProjectList" :data="completedProjectList" style="width: 100%" v-on:sort-change="sortHandler" :row-class-name="getRowClassName" >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="table-container-expand" v-if="scope.row.SubJobs.length > 0">
              <el-table v-if="scope.row.SubJobs" :data="scope.row.SubJobs" style="width: 98%">
                <el-table-column label="Supplier" min-width="130">
                  <template slot-scope="scope">
                    <span v-if="scope.row.Supplier != null"> {{ scope.row.Supplier.Title }}</span>
                    <span v-else> No suppliers yet </span>
                  </template>
                </el-table-column>
                <el-table-column prop="Quantity" label="Order Quantity" min-width="130" > </el-table-column>
                <el-table-column label="Final Production Quantity" min-width="130" v-if="![2].includes(user.Role)"> 
                  <template slot-scope="scope">
                    <span >{{ scope.row.Production.TotalProductionDetails.FinalProductionQuantity }}</span>
                    <!-- <span v-else> No production yet </span> -->
                  </template>
                </el-table-column>
                <el-table-column label="Ready To Load" min-width="80" v-if="![2].includes(user.Role)">
                  <template slot-scope="scope">
                    <span >{{ scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
                    <!-- <span v-else> No production yet </span> -->
                  </template>
                </el-table-column>
                 <el-table-column label="Shipped" min-width="300" v-if="![2].includes(user.Role)">
                  <template slot-scope="scope">
                    <div  class="status" :class="getTransferClassStatus(item.Status)" style="float: left;margin-right: 5px;margin-bottom: 5px;" v-for="(item, index) in scope.row.Transfers" :key="item.ID + index">
                      <span >{{ item.Shipment.ProductQuantity}}  {{getEnumsDisplay("TransferStatus", item.Status) }}</span>
                    </div>
                    <span v-if="scope.row.Transfers.length < 1">0</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Shipped" min-width="130" >
                  <template slot-scope="scope">
                    <span >{{ scope.row.Operation.TotalProductionDetails.FinalProductionQuantity - scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column label="Remaining Quantity" min-width="170">
                  <template slot-scope="scope">
                    <span v-if="scope.row.Stage == 2 || scope.row.Stage == 3">{{ scope.row.Quantity }}</span>
                    <span v-else> {{ scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
                  </template>
                </el-table-column> -->
                 <el-table-column prop="stage" label="Stage" min-width="130">
                  <template slot-scope="scope">
                    <div class="status primary">
                      <span v-if="getEnums">{{ getEnumsDisplay("JobStage", scope.row.Stage) }}</span>
                    </div>
                  </template>
                </el-table-column> 
                <el-table-column prop="status" label="Status" min-width="130">
                  <template slot-scope="scope">
                    <div class="status" :class="getClassStatus(scope.row.Status)">
                      <span v-if="getEnums">{{ getEnumsDisplay("JobStatus", scope.row.Status) }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Actions" width="180">
                  <template slot-scope="scope">
                    <el-dropdown>
                      <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <span class="view-edit" @click="openProject(scope.row.ID)"><i class="icon-pen-to-square-solid"></i>Details</span>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item>
                          <span class="view-edit"><i class="icon-paper"></i>Send Notification</span>
                        </el-dropdown-item> -->
                        <!-- <el-dropdown-item v-if="scope.row.Status != 20">
                          <span class="delete-item" @click="suspendProject(scope.row.ID)"><i class="icon-ban-solid"></i>Suspend</span>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="scope.row.Status == 20">
                          <span class="confirm-quatation" @click="suspendProject(scope.row.ID)"><i class="icon-check-double-solid"></i>Unsuspend</span>
                        </el-dropdown-item> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            </template>
          </el-table-column>
        <el-table-column label="Customer" min-width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.CustomerMini" style="text-weight: bold !important">{{ scope.row.CustomerMini.CompanyName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Proforma No" width="85" sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.ProformaNo != null">{{ scope.row.ProformaNo }}</span>
            <span v-else>{{ scope.row.ReferanceNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Product.SKU" label="SKU" min-width="80"> </el-table-column>
        <el-table-column prop="Product.Title" label="Product" min-width="200"> </el-table-column>
        <el-table-column label="Product Manager" min-width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.ProductManagerId != null"> {{ scope.row.ProductManager.Title }}</span>
            <span v-else> No product manager yet </span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Supplier" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.Supplier != null"> {{ scope.row.Supplier.Title }}</span>
            <span v-else> No suppliers yet </span>
          </template>
        </el-table-column> -->
        <el-table-column prop="Quantity" label="Quantity" min-width="110" sortable="custom"> </el-table-column>
        <el-table-column prop="RemainingQuantity" label="Remaining Quantity" min-width="110" sortable="custom" v-if="![2].includes(user.Role)">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.Stage == 2 || scope.row.Stage == 3">{{ scope.row.Quantity }}</span>
            <span v-else> {{ scope.row.Operation.RemainingQuantity.FinalProductionQuantity }}</span>
          </template> -->
        </el-table-column>
        <el-table-column label="Invoice" min-width="130" v-if="![2].includes(user.Role)">
          <template slot-scope="scope">
            <span v-if="scope.row.CustomerInvoiceInfos.CustomerInvoiceQuantity!=0">  Quantity : {{scope.row.CustomerInvoiceInfos.CustomerInvoiceQuantity}}</span>
            <span v-else >No Invoice</span><br>
            <span v-if="scope.row.CustomerInvoiceInfos.CustomerInvoiceDate !=null">  Date : {{ $moment(scope.row.CustomerInvoiceInfos.CustomerInvoiceDate ).format("DD-MM-YYYY") }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Invoice Date" min-width="130" >
          <template slot-scope="scope">
            <span v-if="scope.row.CustomerInvoiceInfos.CustomerInvoiceDate !=null"> Invoice Date : {{ $moment(scope.row.CustomerInvoiceInfos.CustomerInvoiceDate ).format("DD-MM-YYYY") }}</span>
            <span v-else >No Invoice</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="stage" label="Stage" min-width="130">
          <template slot-scope="scope">
            <div class="status primary">
              <span v-if="getEnums">{{ getEnumsDisplay("JobStage", scope.row.Stage) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status" min-width="200">
          <template slot-scope="scope">
            <div class="status" :class="getClassStatus(scope.row.Status)">
              <span v-if="getEnums">{{ getEnumsDisplay("JobStatus", scope.row.Status) }}</span>
            </div>
          </template>
        </el-table-column> -->

        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="openProject(scope.row.ID)"><i class="icon-pen-to-square-solid"></i>Details</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item>
                  <span class="view-edit"><i class="icon-paper"></i>Send Notification</span>
                </el-dropdown-item> -->
                <!-- <el-dropdown-item v-if="scope.row.Status != 20">
                  <span class="delete-item" @click="suspendProject(scope.row.ID)"><i class="icon-ban-solid"></i>Suspend</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.Status == 20">
                  <span class="confirm-quatation" @click="suspendProject(scope.row.ID)"><i class="icon-check-double-solid"></i>Unsuspend</span>
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>

          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog top="5vh" :close-on-click-modal="false" :visible.sync="dialogVisible" :destroy-on-close="true" :show-close="false">
      <projectsDetailDialog :id="setId" @close="closeAddDialog()" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>
<style>
  .hide-expand .el-table__expand-icon {
    display: none !important;
  }
</style>
<script>
import projectsDetailDialog from "../../components/projectsDetailDialog.vue";
export default {
  components: {
    projectsDetailDialog,
  },
  data() {
    return {
      setId: null,
      dateRange: null,
      dialogVisible: false,
      filterActive: false,
      form: {
        //DEĞİŞECEK
        ID: "",
        AuthorizedPerson: "",
        CountryId: "",
        CityId: "",
        PostalCode: "",
        Address: "",
        Email: "",
        ContactNumber: "",
        Notes: "",
        Title: "",
      },
      expandedKeys:[],
      requestQueue:[]
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getSuppliersList", { Page: 1, PageSize: 999999, SearchTerm: null }),
    await this.$store.dispatch("getCompletedProjectsList");
    this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null });
    // this.getUserList.map((x) => {
    //   this.items.push({
    //     value: x.FirstName + x.LastName,
    //     label: x.FirstName + " " + x.LastName,
    //   });
    // });
    // this.expandRows();
    console.log(this.completedProjectList)
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  computed: {
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    completedProjectList() {
      return this.$store.getters.getCompletedProjectsList;
    },
    filter() {
      return this.$store.getters.getProjectsFilter;
    },
    totalCount() {
      return this.$store.getters.getProjectsTotal;
    },
    user() {
      return this.$store.getters.getUser;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    getUserList() {
      return this.$store.getters.getUserList;
    },
    suppliersList() {
      return this.$store.getters.getSuppliersList;
    },
  },
  methods: {
    expandRows(){
      this.completedProjectList.forEach((item) => {
        if(item.SubJobs.length > 0){
          this.expandedKeys.push(item.ID)
        }
      });
    },
    getRowClassName(row) {
        if (row.row.SubJobs.length < 1) {
          return 'hide-expand';
        }
    },
    async sortHandler(params) {
      // params objesi: {columns, prop, order}
      console.log(params);
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    arrowsRotate() {
      this.$store.dispatch("getCompletedProjectsList");
    },
    closeAddDialog() {
      this.dialogVisible = false;
    },
    openProject(id) {
      this.setId = id;
      this.dialogVisible = true;
    },
    async suspendProject(id) {
      var payload = {
        ID: id,
      };

      this.$confirm("Are you sure suspend or unsuspend this account?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Job/SuspendJobToggle", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getCompletedProjectsList");
          }
        })
        .catch(() => {});
    },

    clearFilter() {
      this.filter.SearchTerm = null;
      this.filter.StartDate = null;
      this.filter.EndDate = null;
      this.filter.CountryId = null;
      this.filter.CityId = null;
      this.filter.ProductManagerId = null;
      this.filter.Stage = null;
      this.filter.Status = null;
      this.dateRange = null;
    },
    getTransferClassStatus(status){
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "info";
          break;
      }
      return res;
    },
    getClassStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "info";
          break;
        case 2:
          res = "success";
          break;
        case 3:
          res = "info";
          break;
        case 4:
          res = "warning";
          break;
        case 5:
          res = "danger";
          break;
        case 6:
          res = "warning";
          break;
        case 7:
          res = "info";
          break;
        case 8:
          res = "info";
          break;
        case 9:
          res = "success";
          break;
        case 10:
          res = "danger";
          break;
        case 11:
          res = "success";
          break;
        case 12:
          res = "success";
          break;
        case 13:
          res = "success";
          break;
        case 14:
          res = "info";
          break;
        case 15:
          res = "info";
          break;
        case 16:
          res = "info";
          break;
        case 17:
          res = "info";
          break;
        case 18:
          res = "info";
          break;
        case 19:
          res = "danger";
          break;
        case 20:
          res = "danger";
          break;
        case 33:
          res = "success";
          break;
        case 34:
          res = "info";
          break;
        case 35:
          res = "warning";
          break;
        case 36:
          res = "warning";
          break;
        case 37:
          res = "info";
          break;
        case 38:
          res = "info";
          break;
        case 39:
          res = "info";
          break;
        case 40:
          res = "info";
          break;
        case 41:
        res = "warning";
        break;
        case 42:
        res = "warning";
        break;
        case 43:
        res = "info";
        break;
        case 44:
        res = "success";
        break;
        case 45:
        res = "warning";
        break;
        case 46:
        res = "success";
        break;
      }
      return res;
    },
    processRequest(newValue) {
      this.requestQueue.push(newValue); // Yeni isteği sıraya ekler

      // Henüz işlem yapılmıyorsa işlemi başlatır
      if (this.requestQueue.length === 1) {
        this.executeRequests();
      }
    },
    executeRequests() {
      // İşlem yapılacak asenkron işlevinizi burada tanımlayın
      // Örneğin, bir API çağrısı yapabilirsiniz
      this.filterSearch().then(()=>{
        this.processNextRequest();
      })
    },
    processNextRequest() {
      this.requestQueue.shift(); 

      if (this.requestQueue.length > 0) {
        this.executeRequests();
      }
    },
    async filterSearch(){
      console.log(this.requestQueue)
      await this.$store.dispatch("getCompletedProjectsList");
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        if (this.dateRange != null && this.dateRange.length > 0) {
          this.filter.StartDate = this.dateRange[0];
          this.filter.EndDate = this.dateRange[1];
        } else {
          this.filter.StartDate = null;
          this.filter.EndDate = null;
          this.dateRange = null;
        }
      },
    },
    filter: {
      deep: true,
      immediate: true, // İlk değeri alırken tetiklenmesini sağlar
      handler: function(newValue) {
        this.processRequest(newValue);
      }
    }
    // filter: {
    //   deep: true,
    //   handler: async function () {
    //     await this.$store.dispatch("getCompletedProjectsList");
    //   },
    // },
  },
};
</script>

<style></style>
